import Vue from 'vue/dist/vue.js'
import customize from './components/customize.vue';
import designer from './components/designer.vue';
import compare from './components/compare.vue';
import dyo from './components/dyo.vue';
import Lightbox from 'vue-easy-lightbox'
import itemProperties from './components/item-properties.vue'
import itemImage from './components/item-image.vue'

Vue.use(Lightbox)

Vue.config.productionTip = false;

Vue.component('item-properties', itemProperties);
Vue.component('item-image', itemImage);

//customize Button
if( document.body.contains(document.getElementsByTagName("customize")[0]) ){
  new Vue({
    el: 'customize',
    delimiters: ['${', '}'],
    render(h) {
      return h(customize)
    }
  });
}

//product Designer
if( document.body.contains(document.getElementsByTagName("designer")[0]) ){
  new Vue({
    el: 'designer',
    delimiters: ['${', '}'],
    render(h) {
      return h(designer)
    }
  });
}


//compare
if( document.body.contains(document.getElementsByTagName("compare")[0]) ){
  new Vue({
    el: 'compare',
    delimiters: ['${', '}'],
    render(h) {
      return h(compare)
    }
  });
}


//design your own
if( document.body.contains(document.getElementsByTagName("dyo")[0]) ){
  new Vue({
    el: 'dyo',
    delimiters: ['${', '}'],
    render(h) {
      return h(dyo)
    }
  });
}


//load cart app
if( document.body.contains(document.getElementById("vue-cart")) ){
  new Vue({
    el: '#vue-cart'
  });
  }

