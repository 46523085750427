
<template>
  <div id="compare-wrapper">
      <div class="anon-wrapper" v-if="!searchID">
          <div style="text-align:center;">
              <div>Login to view you comparison list</div>
              <a class="btn" :href="'/account/login?return_url=' + encodeURIComponent('/pages/compare')">Login to your account</a>
          </div>
      </div>
      
      <div class="emptry-wrapper" v-if="designs.length <= 0 && searchID">
          <div style="text-align:center;">
              <div>You dont have any designs saved for comparison.</div>
              <a class="btn" :href="'/collections/all'">Shop Catalog</a>
          </div>
      </div>

      <div class="share-wrapper" v-if="designs.length > 0">
            <div style="text-align:center;">
                <div> <strong>Share your gallery with friends and family :</strong></div>
                <a class="share-link" :href="'https://shoprsd.com/pages/compare?id=' + this.customerID">https://shoprsd.com/pages/compare?id={{customerID}}</a>
            </div>
          </div>
          
      <div class="compare-list" >

          
          <div class="compare-item" v-for="item in designs" :key="item.index">
              <div class="compare-image" v-html="item.image">

              </div>
                <div class="compare-name">
                    {{item.name}}
                </div>
                <div class="compare-price">
                    ${{Number(item.price).toFixed(2)}}
                </div>
                <div class="compare-options-list">
                    <div class="compare-option" v-for="opt in sorted_options(item.options)" :key="opt.index">
                        <div class="option-title">{{opt.title}}</div>
                        <div class="compare-selection">
                            <img v-if="opt.image" :src="'https://shoprsd.s3.amazonaws.com/'  + opt.image" class="option-image"/>
                            <span class="option-value">{{opt.text}} <span class="upcharge" v-if="opt.price">{{formatPrice(opt.price)}}</span></span>
                        
                        </div>
                        
                    </div> 
                    
                </div>
                <div class="add-to-cart-wrapper">
                    <a class="btn btn--full btn--secondary" v-on:click="addToCart(item)">Add to cart</a>
                </div>
                <div class="option-actions">
                    <div class="option-edit">
                        <a :href="'/pages/product-designer?product=' + item.ShopifyProductID + '&design=' + item.Guid">Modify</a>
                    </div>
                    <div class="option-delete"> 
                        <a v-if="searchID == customerID"  v-on:click="deleteDesign(item.Guid)">Delete</a>
                    </div>
                </div>
          </div>
      </div>
  </div>
</template>

<style lang="css" scoped>
    .compare-list{
        display:flex;
        flex-direction: row;
    }

    .compare-options-list{
        margin-top:25px;
        flex:1;
    }

    .compare-item{
          flex-basis: 25%;
        margin:10px;
        display: flex;
        flex-direction: column;
    }

    .compare-image{
        border:1px solid #c0c0c0;
    }

    .compare-name{
        font-weight:bold;
        font-size:18px;
        margin:10px 0px;
        color:#e15e75;
    }

    .compare-option{
        margin-bottom:20px;
    }
    
    .option-title{
        font-weight:bold;
    }

    .option-image{
        width:30px;
        border-radius:15px;
        margin-right:10px;
    }

    .option-value{
        color:#c0c0c0;
        font-style: italic;
    }

    .add-to-cart-wrapper{
        margin-top:30px;

    }
    .option-actions{
        display:flex;
        flex-direction: row;
        margin-top:15px;
    }

    .option-edit{
        flex:1;
    }

    .option-edit a{
        color:#e15e75;
    }

    .option-delete{
        flex:1;
        text-align:right;
    }

    .option-delete a{
        color:#e15e75;
        cursor: pointer;
    }

    .share-wrapper{
        margin-bottom:10px;
    }
    .share-link{
        text-decoration: underline;
        font-size:14px;
        color:#E15E75;
    }

    .option-guid{
        font-size:10px;
        color:#c0c0c0;
    }
</style>

<script>
import axios from "axios";

export default {
  name: 'compare',
  data: function(){
    return{
      customerID: '',
      compareID: '',
      searchID: null,
      designs:[],
    }
  },
  props: {} ,
  computed:{

  },
  methods:{
      sorted_options(options) {
          return options.sort((a, b) => { return a.displayOrder - b.displayOrder;});
      },
    loadDesigns(){
          axios.get(process.env.VUE_APP_API_URL + '/designs/compare/' + this.searchID, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}})
        .then((response)=>{
            //console.log(response.data);
            this.designs = response.data;

            this.designs.forEach((design)=>{
                var json = JSON.parse(design.DesignJson);
                design.image = json.designImage;
                design.options = json.selectedOptions;
                design.name = json.name;
                design.price = json.price;
                design.sku = json.sku;
            })

            //console.log(this.designs);
        })
        .catch(()=> function(){
            //error
        })
    },
    formatPrice(sPrice){
        if (sPrice != null){
            return "+$" + Number(sPrice).toFixed(0);
        }
    },
    deleteDesign(guid){
        axios.delete(process.env.VUE_APP_API_URL + '/designs/' + guid, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}})
        .then(()=>{
            this.loadDesigns();

        })
        .catch(()=> function(){
            //error
        })
    },
    addToCart(design){
        //console.log('adding to cart');

                var guid = design.Guid;

                //add product to cart
                var properties={};
                design.options.forEach((opt)=>{
                    properties[opt.title] = opt.text;
                })

                properties['_sku'] = design.sku;

                //create unique guid ID to main and child products.
                properties['_option_group'] = guid;

                //add the main product to the cart
                var data = {
                    items: []
                }

                var m = {
                    "id": design.ShopifyVariantID,
                    "quantity": 1,
                    "properties": properties
                }
                data.items.push(m);

                //add all upcharge items
                design.options.forEach((opt)=>{
                   if(opt.upchargeID){
                       var i = {
                           "id": opt.upchargeID.replace("gid://shopify/ProductVariant/", ""),
                           "quantity": 1,
                           "properties":{
                               "_option_group_upcharge": guid
                           }
                       }

                       data.items.push(i);
                   }

                   if(opt.optionUpchargeID){
                       var y = {
                           "id": opt.optionUpchargeID.replace("gid://shopify/ProductVariant/", ""),
                           "quantity": 1,
                           "properties":{
                               "_option_group_upcharge": guid
                           }
                       }

                       data.items.push(y);
                   }

                   
                })



                axios.post('/cart/add.js' , data, {headers: {'content-type': 'application/json'}}) 
                .then((response)=>{
                   // console.log(response);
                    if (response.status==200){
                        //success
                        
                        var redirectUrl = "/cart";
                        window.location.href = redirectUrl;
                    }else{
                        //error
                        console.log('error');
                        this.errors.push(response.description);
                    }

                
                })
                .catch(error => {
                    //console.log('catch');
                    if (error.response) {
                        if (error.response.status==422){
                            this.errors.push(error.response.data.description);
                        }
                  
                    }
                })
                .then( function (msg) {
                   // console.log('finally');
                    console.log(msg);
                }); 
    }
  },
  mounted: function(){
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    this.compareID = params.get("id");
    this.customerID = window.customerId;

    if (this.compareID != null && this.compareID != ''){
        this.searchID = this.compareID;
    }else{

        if (this.customerID && this.customerID != 0){
            this.searchID = this.customerID;
        }
    }

    if (this.searchID){
        //look up the saved design
        this.loadDesigns()
    }

  },
  created: function () {

  }
}
</script>