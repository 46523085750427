
<template>
<div class="designer-wrapper">
    <div v-if="!loaded" style="margin-top:100px;" ><div style="margin:auto;" class="loader"></div><div style="margin-top:10px; color:#c0c0c0; text-align:center; font-weight:700; text-transform: uppercase;">please wait while the custom designer loads</div></div>
    <div v-if="loaded" class="designer-row">
        <div class="designer-image-column">
            <div class="designer-image-wrapper">
                <div class="image" id="svg-container" style="max-width:500px; margin:0 auto;" v-html="designerImageSVG"></div>
            </div>
        </div>
        <div class="designer-options-wrapper">
            <h1 class="h2 product-single__title">
            {{ShopifyProduct.title}}
            </h1>
            <span id="ProductPrice" class="product__price">
            ${{ShopifyProduct.variants[0].price}}
            </span>

            <div class="designer-options-list">
                  <div  class="designer-option" :id="'design-option-' + index"  v-for="(item, index) in options.Groups" :key="item.index" :class="{active: isActive == index}">
                        <div v-on:click="toggleState(index)" class="designer-option-title">{{ item.option.Title }} 
                            
                            <span >{{getSelectedValueNameForTitle(item.option.ID, item.option.OptionType)}}</span>
                            
                        </div>
                        
                        <div v-on:click="toggleState(index)" v-if="item.option.OptionType != 'yes-no' && item.option.OptionType != 'image-picker'">
                            <div class="designer-option-selected" v-if="getSelectedValueName(item.option.ID) != ''">
                                <img :src="getSelectedValueImage(item.option.ID)" class="selected-image-thumb"/>
                                <span v-html="getSelectedValueName(item.option.ID, item.option.OptionType, false)"></span>
                            </div>
                        </div>
                    
                        <div class="designer-option-items-wrapper" :class="{hidden: isActive != index}">

                            <!-- check if this option is required or not -->
                            <div class="optional" v-if="item.option.isOptional == true">
                                 <label><input type="checkbox" :checked="isChecked(item.option.ID)" @change="optionalChecked($event, item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item)"> No Thank You</label>
                            </div>

                        
                           <!-- fabric categories / tags -->
                            <div v-if="item.option.optionTags.length > 0 && item.option.OptionType == 'fabric'" style="margin-bottom:10px;">
                            <ul class="fabric-category-list">
                                <li><a class="fabric-filter" v-on:click="setFabricCategory('')" :class="{selected: fabricCategory == ''}">All</a></li>
                                <li v-for="tag in item.option.optionTags" :key="tag.index"><a class="fabric-filter" v-on:click="setFabricCategory(tag.TagID)" :class="{selected: fabricCategory == tag.TagID}">{{tag.Name}}</a></li>
                            </ul>
                            </div>
                            <div class="designer-option-items" >

                                <!--fabric options-->
                                <div v-if="item.option.OptionType == 'fabric'">
                              
                                    <ul class="designer-option-items-list">
                                        <!-- loop through values looking for the fabric field -->
                                        <li v-for="option in filteredFabrics(item.option.values)" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}" >
                                            <label class="designer-option-item-label" :class="{outOfStock: option.Fabric.isAvailable == false}" v-on:click="option.Fabric.isAvailable == true && selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item, option.ID, option)">
                                                
                                                <span class="designer-option-item-premier" v-if="option.Fabric.isPremier || option.Fabric.LgShopifyPrice || option.Fabric.SmShopifyPrice">
                                                    <span  v-if="option.Fabric.isPremier">Premier </span> 
                                                    <span class="upcharge" v-if="!item.option.isFree"> {{formatPrice(option.Fabric)}}</span>
                                                </span>

                                                <span class="designer-option-item-unavailable" v-if="option.Fabric.isAvailable == false">
                                                    <span >Temp Out of Stock</span> 
                                                </span>

                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.Fabric.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">
                                                {{option.Fabric.Name}}
                                                
                                            </div>
                                            <div class="designer-option-item-enlarge"><a v-on:click="openImage('https://shoprsd.s3.amazonaws.com/' + option.Fabric.ImageUrl)"><span>Enlarge</span></a></div>
                                            <div class="designer-option-item-example" v-if="option.LookbookImages.length > 0"><a v-on:click="openImages(option.LookbookImages)"><span>See examples</span></a></div>
                                        </li>
                                    </ul>
                                </div>

                                <!--leather options-->
                                <div v-if="item.option.OptionType == 'leather'">
                                    <ul class="designer-option-items-list">
                                        <!-- loop through values looking for the leather field -->
                                        <li v-for="option in item.option.values" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}">
                                            <label class="designer-option-item-label" v-on:click="option.Leather.isAvailable == true && selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item,  option.ID, option)">

                                                 <span class="designer-option-item-premier" v-if="option.Leather.isPremier || option.Leather.LgShopifyPrice || option.Leather.SmShopifyPrice">
                                                    <span  v-if="option.Leather.isPremier">Premier </span> 
                                                    <span class="upcharge" v-if="!item.option.isFree"> {{formatPrice(option.Leather)}}</span>
                                                </span>
                                                
                                                <span class="designer-option-item-unavailable" v-if="option.Leather.isAvailable == false">
                                                    <span >Temp Out of Stock</span> 
                                                </span>

                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.Leather.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">{{option.Leather.Name}}
                                            </div>
                                            <div class="designer-option-item-enlarge"><a v-on:click="openImage('https://shoprsd.s3.amazonaws.com/' + option.Leather.ImageUrl)"><span>Enlarge</span></a></div>
                                        </li>
                                    </ul>
                                </div>



                                <!--lining options-->
                                <div v-if="item.option.OptionType == 'lining'">
                                    <ul class="designer-option-items-list">
                                        <!-- loop through values looking for the lining field -->
                                        <li v-for="option in sorted_linings(item.option.values)" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}">
                                            <label class="designer-option-item-label" v-on:click="option.Lining.isAvailable == true &&  selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item,  option.ID, option)">
                                                
                                                <span class="designer-option-item-premier" v-if="option.Lining.isPremier || option.Lining.LgShopifyPrice || option.Lining.SmShopifyPrice">
                                                    <span  v-if="option.Lining.isPremier">Premier </span> 
                                                    <span class="upcharge" v-if="!item.option.isFree"> {{formatPrice(option.Lining)}}</span>
                                                </span>

                                                <span class="designer-option-item-suggested" v-if="isLiningSuggested(option.LiningID) && option.Lining.isAvailable == true">Suggested</span>

                                                <span class="designer-option-item-unavailable" v-if="option.Lining.isAvailable == false">
                                                    <span >Temp Out of Stock</span> 
                                                </span>

                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.Lining.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">{{option.Lining.Name}}
                                            </div>
                                            <div class="designer-option-item-example" v-if="option.LookbookImages.length > 0"><a v-on:click="openImages(option.LookbookImages)"><span>See examples</span></a></div>

                                        </li>
                                    </ul>
                                </div>


                                <!--zipper options-->
                                <div v-if="item.option.OptionType == 'zipper'">
                                    <ul class="designer-option-items-list">
                                        <!-- loop through values looking for the zipper field -->
                                        <li v-for="option in sorted_zippers(item.option.values)" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}">
                                            <label class="designer-option-item-label" v-on:click="option.Zipper.isAvailable == true &&  selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item,  option.ID, option)">
                                                
                                                <span class="designer-option-item-premier" v-if="option.Zipper.isPremier || option.Zipper.LgShopifyPrice || option.Zipper.SmShopifyPrice">
                                                    <span  v-if="option.Zipper.isPremier">Premier </span> 
                                                    <span class="upcharge" v-if="!item.option.isFree"> {{formatPrice(option.Zipper)}}</span>
                                                </span>

                                                <span class="designer-option-item-suggested" v-if="isZipperSuggested(option.ZipperID) && option.Zipper.isAvailable == true">Suggested</span>

                                                <span class="designer-option-item-unavailable" v-if="option.Zipper.isAvailable == false">
                                                    <span >Temp Out of Stock</span> 
                                                </span>

                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.Zipper.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">{{option.Zipper.Name}}
                                            </div>
            
                                        </li>
                                    </ul>
                                </div>

                                <!--accessory zipper options-->
                                <div v-if="item.option.OptionType == 'accessory-zipper'">
                                    <ul class="designer-option-items-list">
                                        <!-- loop through values looking for the  field -->
                                        <li v-for="option in item.option.values" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}">
                                            <label class="designer-option-item-label" v-on:click="option.AccessoryZipper.isAvailable == true &&  selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item,  option.ID, option)">
                                                
                                                <span class="designer-option-item-premier" v-if="option.AccessoryZipper.isPremier || option.AccessoryZipper.LgShopifyPrice || option.AccessoryZipper.SmShopifyPrice">
                                                    <span  v-if="option.AccessoryZipper.isPremier">Premier </span> 
                                                    <span class="upcharge" v-if="!item.option.isFree"> {{formatPrice(option.AccessoryZipper)}}</span>
                                                </span>

                                                <span class="designer-option-item-unavailable" v-if="option.AccessoryZipper.isAvailable == false">
                                                    <span >Temp Out of Stock</span> 
                                                </span>

                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.AccessoryZipper.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">{{option.AccessoryZipper.Name}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <!--webbing options-->
                                <div v-if="item.option.OptionType == 'webbing'">
                                    <ul class="designer-option-items-list">
                                        <!-- loop through values looking for the  field -->
                                        <li v-for="option in item.option.values" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}">
                                            <label class="designer-option-item-label" v-on:click="option.Webbing.isAvailable == true &&  selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item,  option.ID, option)">
                                                
                                                <span class="designer-option-item-premier" v-if="option.Webbing.isPremier || option.Webbing.LgShopifyPrice || option.Webbing.SmShopifyPrice">
                                                    <span  v-if="option.Webbing.isPremier">Premier </span> 
                                                    <span class="upcharge" v-if="!item.option.isFree"> {{formatPrice(option.Webbing)}}</span>
                                                </span>

                                                <span class="designer-option-item-unavailable" v-if="option.Webbing.isAvailable == false">
                                                    <span >Temp Out of Stock</span> 
                                                </span>

                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.Webbing.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">{{option.Webbing.Name}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <!--ribbon options-->
                                <div v-if="item.option.OptionType == 'ribbon'">
                                    <ul class="designer-option-items-list">
                                        <!-- loop through values looking for the  field -->
                                        <li v-for="option in item.option.values" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}">
                                            <label class="designer-option-item-label" v-on:click="option.Ribbon.isAvailable == true &&  selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item,  option.ID, option)">
                                                
                                                <span class="designer-option-item-premier" v-if="option.Ribbon.isPremier || option.Ribbon.LgShopifyPrice || option.Ribbon.SmShopifyPrice">
                                                    <span  v-if="option.Ribbon.isPremier">Premier </span> 
                                                    <span class="upcharge" v-if="!item.option.isFree"> {{formatPrice(option.Ribbon)}}</span>
                                                </span>

                                                <span class="designer-option-item-unavailable" v-if="option.Ribbon.isAvailable == false">
                                                    <span >Temp Out of Stock</span> 
                                                </span>

                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.Ribbon.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">{{option.Ribbon.Name}}
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                                <!--image picker options-->
                                <div v-if="item.option.OptionType == 'image-picker'">
                                    <ul class="designer-option-items-list">
                                      
                                        <li v-for="option in item.option.values" :key="option.index" class="designer-option-item" :class="{selected: isOptionSelected(item.option.ID, option.ID)}">
                                            <label class="designer-option-item-label" v-on:click="selectOption(item.DisplayOrder, item.option.ID, item.option.OptionType, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice, item,  option.ID, option)">
                                                <img class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.ImageUrl" alt="">
                                            </label>
                                            <div class="designer-option-item-title">{{option.ImageText}}</div>
            
                                        </li>
                                    </ul>
                                </div>

                                <!-- yes-no options-->
                                <div v-if="item.option.OptionType == 'yes-no'">
                                    <ul class="designer-option-items-list">
                                        <li v-for="option in item.option.values" :key="option.index" class="designer-option-item-full" >
                                            <div style="display:flex; flex-direction:row; align-items: flex-start;">
                                                <img style="max-width:150px; align-items: flex-start; display:flex; flex-direction:column;" class="designer-option-item-image" v-bind:src="'https://shoprsd.s3.amazonaws.com/' + option.ImageUrl" alt="">
                                                <div style="padding-left:15px;">
                                                      <label><input type="radio" :name="'selBool-' + item.option.ID"  v-on:click="selectBoolOption(item.DisplayOrder, item.option.ID, item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice,  item,  option.ID, option, 'no')">{{option.NoText}}</label>
                                                      <label><input type="radio" :name="'selBool-' + item.option.ID"  v-on:click="selectBoolOption(item.DisplayOrder, item.option.ID,  item.option.Title, item.option.ShopifyVariantID, item.option.ShopifyVariantPrice,  item,  option.ID, option, 'yes')">{{option.YesText}} <span class="upcharge" v-if="option.ShopifyPrice">{{formatPrice(option.ShopifyPrice)}}</span></label>
                                                </div>
                                            </div>
                                            
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="designer-option-notes" v-if="item.option.Notes">
                                <span v-html="item.option.Notes"></span>
                            </div>
                        </div>
                 </div>
            </div>

            <div class="qty-row">
                <div class="qty-wrapper">
                    <div class="qty-title">Quantity</div>
                    <input type="number" class="qty" v-model="quantity" >
                </div>
                <div class="price-wrapper">
                    <span class="price">${{calcTotal}}</span>
                </div>
            </div>

            <div class="add-to-cart-wrapper">
                <div class="add-to-cart">
                    <button type="button" class="btn btn-primary" v-on:click="addToCart()">Add To Cart</button>
                </div>
                <div class="save-design">
                    <div v-if="customerID == 0">
                        <a v-on:click="goToLogin()">Login to save and compare</a>
                    </div>
                    <div v-if="customerID > 0">
                        <a v-on:click="saveAndCompare()">Save and Compare Design</a>
                    </div>
                </div>
            </div>

            <div class="product-errors" v-if="errors.length >0">
                <div class="error-title">Please correct the following errors:</div>
                <ul class="error-list">
                    <li class="error-item" v-for="error in errors" :key="error.index">{{error}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="no-product">
        <!-- We are unable to locate the product selected. -->
    </div>
    {{selectedSku}}
     <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
</div>
</template>


<style lang="css" scoped>
.product-errors{
    background-color:#e15e75;
    padding:10px;
    border:1px solid #bb3049;
    margin-top:10px;
    color:#fff7f9;
}
.error-list{
    list-style-type: none;
    margin:0px;
    padding:0px;
    font-size:12px;
}

.error-title{
    font-weight:bold;
    margin-bottom:5px;
}

.qty-row{
    display:flex;
    flex-direction:row;
}
.qty-wrapper{
    flex:1;
}

.qty{
    width:100px;
}
.price-wrapper{
    display: flex;
    align-items: flex-end;
}

.price{
    margin-top:10px;
    font-size:18px;
    font-weight:bold;
    color:#333;
}

.add-to-cart-wrapper{
    margin-top:20px;
    display:flex;
    flex-direction: row;;
}

.save-design{
    flex:1;
    text-align: right;
}

.save-design a{
    line-height: 1.42;
    font-size: max(calc(var(--typeBaseSize) - 4px), 13px);
    color:pink;
    font-weight: 700;
    padding: 11px 20px;
    display: inline-block;
    cursor: pointer;
}

.fabric-category-list{
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.fabric-category-list li{
    display:inline-block;
}

.fabric-category-list .fabric-filter{
   color:#e15e75;
   margin-right:20px;
}

.fabric-category-list .fabric-filter:hover{
   color:#ce4c64;
}

.fabric-category-list .fabric-filter.selected {
    font-weight: bold;
    border-bottom: 1px solid #ce4c64;
}



.designer-image-column{
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
    padding-right: 100px;
    align-self: flex-start;
    height: auto;
}

.designer-image-wrapper{
    border:1px solid #e4e4e4;
}

    .designer-row{
        display:flex;
        flex-direction:row;
    }

    .designer-image-column{
        flex:5;
    }

    .designer-options-wrapper{
        flex:3;
    }

    .designer-options-list{
        margin-top:100px;
        margin-bottom:20px;
    }

    .designer-option{
        border-top: 1px solid #f3f3f3;
        padding: 18px 0;
        cursor: pointer;
    }

    .designer-options-list .designer-option:last-child{
        border-bottom: 1px solid #f3f3f3;
    }

    .designer-option-title{
        font-weight:bold;
        text-transform: uppercase;
    }

    .designer-option-title::after{
        content: "+";
        position: static;
        float:right;
    }

    .designer-option-title span{
        font-size: 14px;
        text-transform: none;
        font-weight: normal;
        color: #c0c0c0;
        font-style: italic;
    }

    .designer-option.active .designer-option-title::after{
        content: "\2014";
    }

    .designer-option-items-wrapper{
        margin-top:10px;
    }

    .designer-option-notes{
        padding: 15px;
        font-size: 13px;
        background-color: #f3f3f3;
    }

    .designer-option-items{
        transition: all .2s;
        max-height:500px;
        overflow-y:scroll;
        border: 1px solid #f3f3f3;
        padding: 10px 5px;

    }

    .designer-option-items-list{
        list-style-type: none;
        margin:0px;
        padding:0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .designer-option-item{
        display: inline-block;
        width:33%;
        padding:0px 2px;
        margin-bottom:20px;
    }

    .designer-option-item img{
        border: 3px solid #fff;
        padding: 3px;
    }

    .designer-option-item.selected img{
        border: 3px solid #e15e75;
        padding: 3px;
    }

    .designer-option-item.selected  .designer-option-item-title{
        color:#ff7a91;
        font-weight:bold;
    }

    .designer-option-item-label{
        display:inline-block;
        margin-bottom:5px;
        position: relative;

    }

    .designer-option-item-enlarge, .designer-option-item-enlarge a{
        font-size:12px;
        color:#e15e75;
    }

    .designer-option-item-example, .designer-option-item-example a{
        font-size:12px;
        color:#e15e75;
    }


.designer-option-item-premier{
    line-height: 1;
    position: absolute;
    bottom: 11px;
    left: 6px;
    max-width: calc(100% - 10px);
    display: inline-block;
    font-size: 10px;
    font-size: .625rem;
    font-family: Custom Metropolis,sans-serif;
    letter-spacing: 1px;
    letter-spacing: .03125rem;
    color: #fff;
    background-color: #e15e75;
    padding: 4px 7px;
    text-transform: uppercase;
}


.designer-option-item-suggested{
    position: absolute;
    left: 6px;
    top: 6px;
     display: inline-block;
    font-size: 10px;
    font-size: .625rem;
    font-family: Custom Metropolis,sans-serif;
    letter-spacing: 1px;
    letter-spacing: .03125rem;
    color: #fff;
     background-color: #e15e75;
    padding: 4px 7px;
    text-transform: uppercase;
    width: calc(100% - 12px);
    text-align: center;
}

.designer-option-item-unavailable{
    position: absolute;
    left: 6px;
    top: 6px;
     display: inline-block;
    font-size: 10px;
    font-size: .625rem;
    font-family: Custom Metropolis,sans-serif;
    letter-spacing: 1px;
    letter-spacing: .03125rem;
    color: #fff;
     background-color: #333;
    padding: 4px 7px;
    text-transform: uppercase;
    width: calc(100% - 12px);
    text-align: center;
}

    .designer-option-item-title{
        line-height:18px;
    }

     .designer-option-item-title span{
        font-size:12px;
    }


    .selected-image-thumb{
        width:70px;
        border-radius: 35px;
        margin-right:10px;
    }
    .designer-option-selected{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size:14px;
        min-height:80px;
    }
    .hidden{
        display:none;
    }

   /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  --b: 8px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,#f03355) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}

@media (max-width:720px){
    .designer-row{
        flex-direction: column;
    }

    .designer-image-column{
        padding:0px;
        width: 100%;
        background-color: #fff;
        z-index: 999;
        top:60px;
        margin-bottom:20px;
    }

    .designer-option-items-list{
        flex-wrap: nowrap;
    }

    .designer-option-item{
        margin-bottom: 0px;
        min-width: 120px;
    }

    .add-to-cart-wrapper{
        flex-direction: column;
        text-align: center;
    }

    .add-to-cart .btn{
        width:80%;
    }

    .save-design{
        text-align:center;
    }

    #svg-container {
        text-align: center;
    }

     #svg-container >>> .designer-svg{
        max-height:300px;
    }
 
}

</style>

<style lang="css">
    .designer-wrapper .vel-img-title{
        top:10px !important;
        color:#fff !important;
        opacity: 1 !important;
        font-size:16px !important;
        line-height:20px !important;
        background-color:rgba(0,0,0,.3) !important;
        padding:10px !important;
        bottom:unset !important;
    }

@media (max-width:720px){


    .page-full{
        padding-left:0px !important;
         padding-right:0px !important;
    }
}
</style>


<script>
import axios from "axios";
import VueScrollTo from "vue-scrollto";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    name: 'designer',
    components: {
        VueEasyLightbox
     },
    data: function(){
    return{
        customerID:'',
        errors:[],
        quantity: 1,
        totalPrice: '',
        selectedSku:'',
        SkuParts:{
            part1:'',
            part2:'',
            part3:'',
            part4:'',
            part5:'',
            part6:'',
            part7:'',
            part8:'',
            part9:'',
            part10:'',
            part11:''
        },
        fabricCategory: '',
        suggestedLinings:[],
        suggestedZippers:[],
        ShopifyProduct: {},
        designerImageSVG: '',
        product: '',
        productID: '',
        variantID: '',
        designID: '',
        options: {},
        isActive: null,
        selectedOptions:[],
        imgs: '',  // Img Url , string or Array of string
        visible: false,
        index: 0,   // default: 0,
        FabricPattern: 'https://shoprsd.s3.amazonaws.com/base/fabric_base.jpg',
        LeatherPattern:'https://shoprsd.s3.amazonaws.com/base/leather_base.jpg',
        ZipperPattern: 'https://shoprsd.s3.amazonaws.com/base/zipper_base.jpg',
        windowWidth:0,
        loaded: false
    }
},
props: { },
computed:{
    calcTotal(){
        var price = Number(this.ShopifyProduct.variants[0].price);
        this.selectedOptions.forEach(opt=>{
            price = price + Number(opt.price);
            if (opt.optionUpchargePrice != null){
                price = price + Number(opt.optionUpchargePrice);
            }
        })
        return price.toFixed(0);
    }
},
methods:{
    sorted_linings(items){

        var sugg = [];
        var nonSugg = [];

        if (this.suggestedLinings){
           items.forEach((i)=>{
               var ext = this.suggestedLinings.filter(function(el){
                    if(el.LiningID == i.LiningID) {
                        return el;
                    }else{
                        return  null;
                    }
                })

                if (ext.length > 0 ){
                    sugg.push(i);
                }else{
                    nonSugg.push(i);
                } 
            })

            return sugg.concat(nonSugg);

        }else{
            return items;
        }
        
    },
    sorted_zippers(items){

        var sugg = [];
        var nonSugg = [];

        if (this.suggestedZippers){
           items.forEach((i)=>{
               var ext = this.suggestedZippers.filter(function(el){
                    if(el.ZipperID == i.ZipperID) {
                        return el;
                    }else{
                        return  null;
                    }
                })

                if (ext.length > 0 ){
                    sugg.push(i);
                }else{
                    nonSugg.push(i);
                } 
            })

            return sugg.concat(nonSugg);

        }else{
            return items;
        }
        
    },
    saveAndCompare(){

        var harErrors = this.validateForm();
        if (harErrors == false){

            //check to see if there are less than 4 saved designs for the user.

            axios.get(process.env.VUE_APP_API_URL + '/designs/count/' + this.customerID , {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
            .then(()=>{
                
               
                    //save design image
                    var svgWrapper = document.getElementById("svg-container");

                    var savedDesign = {
                        designImage : svgWrapper.innerHTML,
                        selectedOptions : this.selectedOptions,
                        name: this.ShopifyProduct.title,
                        price: this.calcTotal,
                        sku: this.selectedSku,
                        skuParts: this.SkuParts
                    }

                    //post design to database
                    axios.post(process.env.VUE_APP_API_URL + '/designs/' + this.productID + '/' + this.variantID + '/' + this.customerID , savedDesign, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
                    .then((response)=>{
                        
                        if (response){
                            window.location.href = "/pages/compare";
                        }
                    
                    
                    })
                    .catch(()=> function(){
                        //error
                    })
              
                
            })
            .catch(()=> function(){
                //error
            })
        }
        

    },
    goToLogin(){
        //save the design to database
       
        var svgWrapper = document.getElementById("svg-container");

        var savedDesign = {
            designImage : svgWrapper.innerHTML,
            selectedOptions : this.selectedOptions,
            name: this.ShopifyProduct.title,
            price: this.calcTotal,
            skuParts: this.SkuParts
        }

        axios.post(process.env.VUE_APP_API_URL + '/designs/' + this.productID + '/' + this.variantID + '/' + this.customerID , savedDesign, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
        .then((response)=>{
           
            var guid = response.data;
            var redirectUrl = "/account/login?return_url=" + encodeURIComponent("/pages/product-designer?product=" + this.productID + "&design=" + guid);
            window.location.href = redirectUrl;
        
        })
        .catch(()=> function(){
            //error
        })

    },
    formatPrice(material){
        var sPrice;
         if (this.options.TypeSku == "A"){
            //accessory = small
           
            sPrice = material.SmShopifyPrice;
        }
            if (this.options.TypeSku == "P"){
            //purse = large
           sPrice = material.LgShopifyPrice;
        }

        if (sPrice != null){
            return "+$" + Number(sPrice).toFixed(0);
        }
    },
    setFabricCategory(tagID){
        this.fabricCategory = tagID;
        //console.log(this.fabricCategory);
    },
    filteredFabrics(items){
        if (this.fabricCategory != ''){
            return items.filter(item => item.tagList.includes(this.fabricCategory))
        }else{
            return items;
        }
    },
    isLiningSuggested(optionID){
        var ext = this.suggestedLinings.filter(function(el){
            if(el.LiningID == optionID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0 ){
            return true;
        }else{
            return false;
        }
    },
    isZipperSuggested(optionID){
        var ext = this.suggestedZippers.filter(function(el){
            if(el.ZipperID == optionID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0 ){
            return true;
        }else{
            return false;
        }
    },
    isChecked(optionID){
         var ext = this.selectedOptions.filter(function(el){
            if(el.optionID == optionID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0 ){
            var obj = ext[0];
            if (obj.valueID != null){
                return false;
            }else{
                return true;
            }
        }else{
            return false;
        }

    },
    openImage(sPath){
        // opens a single image in a lightbox for the 'enlarge' feature
        this.imgs = sPath;
         this.show();
    },
     openImages(imgs){
        //  opens an array in an image gallery lightbox for he 'see examples' feature
        var lbImageArray=[];

        imgs.forEach((value)=>{
            var i = {
                src : "https://shoprsd.s3.amazonaws.com/" + value.ImageUrl,
                title : value.Title
            }
            lbImageArray.push(i);
        })

        this.imgs = lbImageArray;
        this.index = 0;
        this.show();
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
     toggleState (index){
        //  sets the current active option
        // only 1 option can be open at a time
          this.isActive = (this.isActive === index) ? null : index;
          const el = document.getElementById("design-option-" + index);
          if (el){
              var scrollOptions = {
                lazy: false,
                offset: -75,
                force: true,
                cancelable: true,
                x: false,
                y: true
                }
            
            if (this.windowWidth <= 720 ){
                scrollOptions.offset = -375;
            }

              if (this.isActive != null){
                 VueScrollTo.scrollTo(el, 500, scrollOptions);
              }
              
          }
    },
    updateSkuPart(SkuPart, value){
        //console.log(SkuPart);
       // console.log(value);

        switch (SkuPart) {
        case "1":
            this.SkuParts.part1 = value;
            break;
        case "2":
            this.SkuParts.part2 = value;
            break;
        case "3":
            this.SkuParts.part3 = value;
            break;
        case "4":
            this.SkuParts.part4 = value;
            break;
        case "5":
            this.SkuParts.part5 = value;
            break;
        case "6":
            this.SkuParts.part6 = value;
            break;
        case "7":
            this.SkuParts.part7 = value;
            break;
        case "8":
            this.SkuParts.part8 = value;
            break;
        case "9":
            this.SkuParts.part9 = value;
            break;
        case "10":
            this.SkuParts.part10 = value;
            break;
        case "11":
            this.SkuParts.part11 = value;
            break;
        }

       console.log(this.SkuParts);
    },
    optionalChecked(event, DisplayOrder, optionID, optionType, optionTitle, optionUpchargeID, optionUpchargePrice, group){
        //console.log(event);
        //console.log(optionID);
        if (event.target.checked == true){
            this.selectOption(DisplayOrder, optionID, optionType, optionTitle, null, null, group, null, null);
        }
    },
    selectBoolOption(DisplayOrder, optionID, optionTitle, optionUpchargeID, optionUpchargePrice, group, valueID, valueObj, selValue){

        //console.log(selValue);

        // check if selectedOption array contains ovalue for selected option
        var ext = this.selectedOptions.filter(function(el){
            if(el.optionID == optionID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0 ){
            //console.log('has existing value');
            // remove existing value(s) for this option
            ext.forEach((el) => {
                this.selectedOptions.splice(this.selectedOptions.indexOf(el), 1);
            })
        }

        //add a new value
        if (valueObj != null){
            //optionID, valueID, textValue, image, premiereID
            var newOpt = {
                groupID: group.ID,
                group:group,
                optionID: optionID, 
                valueID: valueID, 
                displayOrder: DisplayOrder,
                text: '',
                title: optionTitle,
                image: '',
                upchargeID: '',
                optionUpchargeID: optionUpchargeID,
                optionUpchargePrice: optionUpchargePrice,
                price: '',
                sku:'',
                boolValue: selValue,
                valueObj: valueObj
            };

            if (selValue == 'yes'){
                //console.log(selValue);
                newOpt.text = valueObj.YesText;

                if (group.SkuPart !=''){
                    this.updateSkuPart(group.SkuPart, valueObj.sku);
                }

                if (valueObj.isPremier){
                    newOpt.upchargeID = valueObj.ShopifyVariantID;
                    newOpt.price = valueObj.ShopifyPrice;
                }
                
            }else{
               // console.log(valueObj.NoText);
                newOpt.text = valueObj.NoText;

                if (group.SkuPart !=''){
                     this.updateSkuPart(group.SkuPart, '');
                }
            }

            this.selectedOptions.push(newOpt);
        }
    },
    selectOption(DisplayOrder, optionID, optionType, optionTitle, optionUpchargeID, optionUpchargePrice, group, valueID, valueObj, skipUpdateLinked){
        // adds the selected option to the array of selected options

        // check if selectedOption array contains ovalue for selected option
        var ext = this.selectedOptions.filter(function(el){
            if(el.optionID == optionID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0 ){
           // console.log('has existing value');
            // remove existing value(s) for this option
            ext.forEach((el) => {
                this.selectedOptions.splice(this.selectedOptions.indexOf(el), 1);
            })
        }
       
       var newOpt = {
            groupID: group.ID,
            group: group,
            optionID: optionID, 
            valueID: valueID, 
            displayOrder: DisplayOrder,
            text: '',
            title: optionTitle,
            image: '',
            upchargeID: '',
            optionUpchargeID: optionUpchargeID,
            optionUpchargePrice: optionUpchargePrice,
            price: '',
            valueObj: valueObj
        };

            //add a new value
            if (valueObj != null){
                //optionID, valueID, textValue, image, premiereID

                
               
                if (optionType=='fabric'){
                    newOpt.text = valueObj.Fabric.Name;
                    newOpt.image = valueObj.Fabric.ImageUrl;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.Fabric.Sku);
                    }
                     if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }

                    if (group.option.isFree == false){
                        //charge for option
                        if (this.options.TypeSku == "A"){
                            //accessory = small
                            newOpt.upchargeID = valueObj.Fabric.SmShopifyVariantID;
                            newOpt.price = valueObj.Fabric.SmShopifyPrice;
                        }
                         if (this.options.TypeSku == "P"){
                            //purse = large
                            newOpt.upchargeID = valueObj.Fabric.LgShopifyVariantID;
                            newOpt.price = valueObj.Fabric.LgShopifyPrice;
                        }

                    }else{
                        newOpt.upchargeID = null;
                        newOpt.price = null;
                    }
             

                    //set the pattern image
                    if (group.ImageLayer != ''){
                        //var fabricSVG = this.designerImageSVG;
                        var fabricTags = document.getElementsByClassName(group.ImageLayer + "-image");
                        Array.prototype.forEach.call(fabricTags, function(el) {
                           // console.log(el);
                            el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', "https://shoprsd.s3.amazonaws.com/" + valueObj.Fabric.PatternImageUrl);
                        });
                    }
                     


                    //look up the suggested linings for this fabric
                    axios.get(process.env.VUE_APP_API_URL + '/linings/builder/suggested/' + valueObj.FabricID, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
                    .then((response)=>{
                        //console.log('checking shopify');
                       // console.log(response.data);
                        this.suggestedLinings = response.data;
                 
                    })
                    .catch(()=> function(){
                        //error
                    })

                    //look up the suggested zippers for this fabric
                    axios.get(process.env.VUE_APP_API_URL + '/zippers/builder/suggested/' + valueObj.FabricID, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
                    .then((response)=>{
                        //console.log('checking shopify');
                       // console.log(response.data);
                        this.suggestedZippers = response.data;
                 
                    })
                    .catch(()=> function(){
                        //error
                    })

                }
                
                if (optionType=='leather'){
                    newOpt.text = valueObj.Leather.Name;
                    newOpt.image = valueObj.Leather.ImageUrl;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.Leather.Sku);
                    }
                     if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }

                    if (group.option.isFree == false){
                        //charge for option
                        if (this.options.TypeSku == "A"){
                            //accessory = small
                            newOpt.upchargeID = valueObj.Leather.SmShopifyVariantID;
                            newOpt.price = valueObj.Leather.SmShopifyPrice;
                        }
                         if (this.options.TypeSku == "P"){
                            //purse = large
                            newOpt.upchargeID = valueObj.Leather.LgShopifyVariantID;
                            newOpt.price = valueObj.Leather.LgShopifyPrice;
                        }

                    }else{
                        newOpt.upchargeID = null;
                        newOpt.price = null;
                    }

                    //set the pattern image
                    if (group.ImageLayer != ''){
                        var leatherTags = document.getElementsByClassName(group.ImageLayer + "-image");
                        Array.prototype.forEach.call(leatherTags, function(el) {
                           // console.log(el);
                            el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', "https://shoprsd.s3.amazonaws.com/" + valueObj.Leather.PatternImageUrl);
                        });
                    }
                }

                if (optionType=='lining'){
                    newOpt.text = valueObj.Lining.Name;
                    newOpt.image = valueObj.Lining.ImageUrl;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.Lining.Sku);
                    }
                     if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }
                    
                    if (group.option.isFree == false){
                        //charge for option
                        if (this.options.TypeSku == "A"){
                            //accessory = small
                            newOpt.upchargeID = valueObj.Lining.SmShopifyVariantID;
                            newOpt.price = valueObj.Lining.SmShopifyPrice;
                        }
                         if (this.options.TypeSku == "P"){
                            //purse = large
                            newOpt.upchargeID = valueObj.Lining.LgShopifyVariantID;
                            newOpt.price = valueObj.Lining.LgShopifyPrice;
                        }

                    }else{
                        newOpt.upchargeID = null;
                        newOpt.price = null;
                    }

                }

                if (optionType=='zipper'){
                    newOpt.text = valueObj.Zipper.Name;
                    newOpt.image = valueObj.Zipper.ImageUrl;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.Zipper.Sku);
                    }
                     if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }

                    if (group.option.isFree == false){
                        //charge for option
                        if (this.options.TypeSku == "A"){
                            //accessory = small
                            newOpt.upchargeID = valueObj.Zipper.SmShopifyVariantID;
                            newOpt.price = valueObj.Zipper.SmShopifyPrice;
                        }
                         if (this.options.TypeSku == "P"){
                            //purse = large
                            newOpt.upchargeID = valueObj.Zipper.LgShopifyVariantID;
                            newOpt.price = valueObj.Zipper.LgShopifyPrice;
                        }

                    }else{
                        newOpt.upchargeID = null;
                        newOpt.price = null;
                    }

                      //set the pattern image
                    if (group.ImageLayer != ''){
                        var zipperTags = document.getElementsByClassName(group.ImageLayer + "-image");
                        Array.prototype.forEach.call(zipperTags, function(el) {
                           // console.log(el);
                            el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', "https://shoprsd.s3.amazonaws.com/" + valueObj.Zipper.PatternImageUrl);
                        });
                    }

                    if (skipUpdateLinked != true){
                        //check to see if there are other zipper types. if so, set the other zipper colors to the same
                        var otherZippers = this.selectedOptions.filter(function(el){
                            if(el.optionID != optionID && el.valueObj && el.valueObj.ZipperID != null) {
                                return el;
                            }else{
                                return  null;
                            }
                        })

                        if (otherZippers.length > 0){
                            //console.log(otherZippers.length);
                            otherZippers.forEach((el) => {
                                
                            //console.log(el);
                            //only reseet the other zipper options if they have a selected value 
                                if (valueID && valueObj){
                                    //look through the group of the previous object and find the value with the matching zipperID
                                    el.group.option.values.forEach((v) => {
                                        if (v.ZipperID == valueObj.ZipperID ){
                                            this.selectOption(el.DisplayOrder, el.optionID, "zipper", el.title, el.optionUpchargeID, el.optionUpchargePrice, el.group, v.ID, v, true)
                                        }
                                    })
                                   
                                }
                                
                            });
                            
                        }
                    }
                   

                }


                if (optionType=='accessory-zipper'){
                    newOpt.text = valueObj.AccessoryZipper.Name;
                    newOpt.image = valueObj.AccessoryZipper.ImageUrl;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.AccessoryZipper.Sku);
                    }
                    if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }

                    if (group.option.isFree == false){
                        //charge for option
                        if (this.options.TypeSku == "A"){
                            //accessory = small
                            newOpt.upchargeID = valueObj.AccessoryZipper.SmShopifyVariantID;
                            newOpt.price = valueObj.AccessoryZipper.SmShopifyPrice;
                        }
                         if (this.options.TypeSku == "P"){
                            //purse = large
                            newOpt.upchargeID = valueObj.AccessoryZipper.LgShopifyVariantID;
                            newOpt.price = valueObj.AccessoryZipper.LgShopifyPrice;
                        }

                    }else{
                        newOpt.upchargeID = null;
                        newOpt.price = null;
                    }

                      //set the pattern image
                    if (group.ImageLayer != ''){
                        var accessoryZipperTags = document.getElementsByClassName(group.ImageLayer + "-image");
                        Array.prototype.forEach.call(accessoryZipperTags, function(el) {
                           // console.log(el);
                            el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', "https://shoprsd.s3.amazonaws.com/" + valueObj.AccessoryZipper.PatternImageUrl);
                        });
                    }

                }

                if (optionType=='webbing'){
                    newOpt.text = valueObj.Webbing.Name;
                    newOpt.image = valueObj.Webbing.ImageUrl;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.Webbing.Sku);
                    }
                     if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }

                    if (group.option.isFree == false){
                        //charge for option
                        if (this.options.TypeSku == "A"){
                            //accessory = small
                            newOpt.upchargeID = valueObj.Webbing.SmShopifyVariantID;
                            newOpt.price = valueObj.Webbing.SmShopifyPrice;
                        }
                         if (this.options.TypeSku == "P"){
                            //purse = large
                            newOpt.upchargeID = valueObj.Webbing.LgShopifyVariantID;
                            newOpt.price = valueObj.Webbing.LgShopifyPrice;
                        }

                    }else{
                        newOpt.upchargeID = null;
                        newOpt.price = null;
                    }

                      //set the pattern image
                    if (group.ImageLayer != ''){
                        var webbingTags = document.getElementsByClassName(group.ImageLayer + "-image");
                        Array.prototype.forEach.call(webbingTags, function(el) {
                           // console.log(el);
                            el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', "https://shoprsd.s3.amazonaws.com/" + valueObj.Webbing.PatternImageUrl);
                        });
                    }

                }


                if (optionType=='ribbon'){
                    newOpt.text = valueObj.Ribbon.Name;
                    newOpt.image = valueObj.Ribbon.ImageUrl;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.Ribbon.Sku);
                    }
                     if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }

                    if (group.option.isFree == false){
                        //charge for option
                        if (this.options.TypeSku == "A"){
                            //accessory = small
                            newOpt.upchargeID = valueObj.Ribbon.SmShopifyVariantID;
                            newOpt.price = valueObj.Ribbon.SmShopifyPrice;
                        }
                         if (this.options.TypeSku == "P"){
                            //purse = large
                            newOpt.upchargeID = valueObj.Ribbon.LgShopifyVariantID;
                            newOpt.price = valueObj.Ribbon.LgShopifyPrice;
                        }

                    }else{
                        newOpt.upchargeID = null;
                        newOpt.price = null;
                    }

                      //set the pattern image
                    if (group.ImageLayer != ''){
                        var ribbonTags = document.getElementsByClassName(group.ImageLayer + "-image");
                        Array.prototype.forEach.call(ribbonTags, function(el) {
                           // console.log(el);
                            el.setAttributeNS('http://www.w3.org/1999/xlink', 'href', "https://shoprsd.s3.amazonaws.com/" + valueObj.Ribbon.PatternImageUrl);
                        });
                    }

                }





                if (optionType =='image-picker'){
                    newOpt.text = valueObj.ImageText;
                    newOpt.image = valueObj.ImageUrl;
                    newOpt.upchargeID = valueObj.ShopifyVariantID;
                    newOpt.price = valueObj.ShopifyPrice;
                    if (group.SkuPart !=''){
                         this.updateSkuPart(group.SkuPart, valueObj.sku);
                    }
                    if (group.OptionalSkuPart !=''){
                            this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalYesSku);
                    }
                }

                

            }else{
                
                //all optional items will not have a valueObj assigned
                if (optionType =='image-picker'){
                    newOpt.text = "No thank you";
                }

                if (group.OptionalSkuPart !=''){
                        this.updateSkuPart(group.OptionalSkuPart, group.option.OptionalNoSku);
                }

            }

            
            this.selectedOptions.push(newOpt);
          //  console.log(this.selectedOptions);


    },
    isOptionSelected(optionID, valueID){

        // check to seee if the current option is selected
        // used to display the active class to indicate it is selected
        var ext = this.selectedOptions.filter(function(el){
            if(el.optionID == optionID && el.valueID == valueID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0){
            return true;
        }else{
            return false;
        }
    },
    getSelectedValueImage(optionID){

        // get the image from the option value
        // images are stored in different locations depending on the type of option/value

        var ext = this.selectedOptions.filter(function(el){
            if(el.optionID == optionID) {
                return el;
            }else{
                return  null;
            }
        })


        if (ext.length > 0){
             return "https://shoprsd.s3.amazonaws.com/" + ext[0].image;

          
        }else{
            return  require("@/assets/blank-selection.png");
        }
    },
    getSelectedValueNameForTitle(optionID, optionType){
       
       
        // get the Name from the option value
        // the name values are stored in different locations depending on the type of option/value

        var ext = this.selectedOptions.filter(function(el){
            if(el.optionID == optionID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0){
           // console.log(ext.length);

            var obj = ext[0];
            //console.log(obj.valueID);
            if (obj.valueID != null && obj.valueID != 'null'){
                if (optionType =='image-picker' || optionType=='yes-no'){
                    return  obj.text;
                }
                
            }else{
                 return "No thank you";
            }
             
        }else{
     
               return null;
        }
    },
    getSelectedValueName(optionID){
       
       
        // get the Name from the option value
        // the name values are stored in different locations depending on the type of option/value

        var ext = this.selectedOptions.filter(function(el){
            if(el.optionID == optionID) {
                return el;
            }else{
                return  null;
            }
        })

        if (ext.length > 0){
            var obj = ext[0];
            return  obj.text;

        }else{
            return null;
        }
    },
    addToCart(){
        var hasErrors = this.validateForm();
        //console.log("has errors: " + hasErrors);
        if (hasErrors ==  false){

            //first save the design to the database to get the idenetifier
             var svgWrapper = document.getElementById("svg-container");

            var savedDesign = {
                designImage : svgWrapper.innerHTML,
                selectedOptions : this.selectedOptions,
                name: this.ShopifyProduct.title,
                price: this.calcTotal,
                sku: this.selectedSku,
                skuParts: this.SkuParts
            }

            axios.post(process.env.VUE_APP_API_URL + '/designs/' + this.productID + '/' + this.variantID + '/' + this.customerID , savedDesign, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
            .then((response)=>{
            
                var guid = response.data;

                //add product to cart
                var properties={};
                this.selectedOptions.forEach((opt)=>{
                    properties[opt.title] = opt.text;
                })

                properties['_sku'] = this.selectedSku;

                //create unique guid ID to main and child products.
                properties['_option_group'] = guid;

                //add the main product to the cart
                var data = {
                    items: []
                }

                var m = {
                    "id": this.variantID,
                    "quantity": 1,
                    "properties": properties
                }
                data.items.push(m);

                //add all upcharge items
                this.selectedOptions.forEach((opt)=>{
                   if(opt.upchargeID){
                       var i = {
                           "id": opt.upchargeID.replace("gid://shopify/ProductVariant/", ""),
                           "quantity": 1,
                           "properties":{
                               "_option_group_upcharge": guid
                           }
                       }

                       data.items.push(i);
                   }

                   if(opt.optionUpchargeID){
                       var y = {
                           "id": opt.optionUpchargeID.replace("gid://shopify/ProductVariant/", ""),
                           "quantity": 1,
                           "properties":{
                               "_option_group_upcharge": guid
                           }
                       }

                       data.items.push(y);
                   }

                   
                })



                axios.post('/cart/add.js' , data, {headers: {'content-type': 'application/json'}}) 
                .then((response)=>{
                   // console.log(response);
                    if (response.status==200){
                        //success
                        
                        var redirectUrl = "/cart";
                        window.location.href = redirectUrl;
                    }else{
                        //error
                       // console.log('error');
                        this.errors.push(response.description);
                    }

                
                })
                .catch(error => {
                    //console.log('catch');
                    if (error.response) {
                        if (error.response.status==422){
                            this.errors.push(error.response.data.description);
                        }
                  
                    }
                })
                .then( function (msg) {
                   // console.log('finally');
                    console.log(msg);
                }); 

                
            
            })
            .catch(()=> function(){
                //error
            })

                    
            


        }
    },
    validateForm(){
       
        //build Sku
      
        var skuBuilder = '';

        skuBuilder = skuBuilder + this.options.LimitedEditionSku;
        skuBuilder = skuBuilder + this.options.TypeSku;
        skuBuilder = skuBuilder + this.options.StyleSku;
        skuBuilder = skuBuilder + '-'

        //console.log(this.SkuParts);
        //fabric
        if (this.SkuParts.part4 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part4;
        }else{
            skuBuilder = skuBuilder + 'S000';
        }
        skuBuilder = skuBuilder + '-'

        //leather
        if (this.SkuParts.part5 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part5;
        }else{
            skuBuilder = skuBuilder + '0';
        }
        skuBuilder = skuBuilder + '-'

        //lining
        if (this.SkuParts.part6 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part6;
        }else{
            skuBuilder = skuBuilder + '000';
        }
        skuBuilder = skuBuilder + '-'

        //zipper
        if (this.SkuParts.part7 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part7;
        }else{
            skuBuilder = skuBuilder + '00';
        }
        skuBuilder = skuBuilder + '-'



        if (this.SkuParts.part8 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part8;
        }else{
            skuBuilder = skuBuilder + '0';
        }
        if (this.SkuParts.part9 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part9;
        }else{
            skuBuilder = skuBuilder + '0';
        }
        if (this.SkuParts.part10 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part10;
        }else{
            skuBuilder = skuBuilder + '0';
        }
        if (this.SkuParts.part11 != ''){
            skuBuilder = skuBuilder + this.SkuParts.part11;
        }else{
            skuBuilder = skuBuilder + '0';
        }
        

        this.selectedSku = skuBuilder;

        //(this.selectedOptions);

        //validate fields.
       this.errors = [];

         this.options.Groups.forEach(optGroup => {
            //loop through the selected optioins to see if there is an option that matches
                var ext = this.selectedOptions.filter(function(el){
                if(el.optionID == optGroup.option.ID) {
                    return el;
                }else{
                    return  null;
                }
            })

            if (ext.length > 0 ){
                //has option
            }else{
                //missing required option
                this.errors.push("Required: " + optGroup.option.Title);
            }
         });

         //check quantity
         if (!this.quantity){
             this.errors.push("Enter quantity you would like to purchase");
         }

          if (this.quantity <= 0){
             this.errors.push("Quantity must be at least 1 to purchase");
         }

       

        if(this.errors.length >0){
            return true;
        }else{
            return false;
        }

    }
},
mounted: function(){
    window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
    })

    //check the customerID
    this.customerID = window.customerId;
    if (this.designID != ''){
        
            console.log('look up design from database');
                    
                    //get the design from the database
                    axios.get(process.env.VUE_APP_API_URL + '/designs/' + this.designID, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
                    .then((response)=>{
                      
                        var ret = JSON.parse(response.data.DesignJson);

                       // console.log(ret);

                        var svg = ret.designImage;
                        //  //load designer image

                        //set max height if mobile
                        if (this.windowWidth <= 720){
                            svg = svg.replaceAll("<svg", "<svg class='designer-svg' ");
                        }

                        svg = svg.replaceAll("[fabric]", this.FabricPattern)
                        svg = svg.replaceAll("[leather]", this.LeatherPattern)
                        svg = svg.replaceAll("[zipper]", this.ZipperPattern)
                        this.designerImageSVG = svg;


                        // console.log(this.designerImageSVG);

                        this.selectedOptions = ret.selectedOptions;
                        this.SkuParts.part1 = ret.skuParts.part1;
                        this.SkuParts.part2 = ret.skuParts.part2;
                        this.SkuParts.part3 = ret.skuParts.part3;
                        this.SkuParts.part4 = ret.skuParts.part4;
                        this.SkuParts.part5 = ret.skuParts.part5;
                        this.SkuParts.part6 = ret.skuParts.part6;
                        this.SkuParts.part7 = ret.skuParts.part7;
                        this.SkuParts.part8 = ret.skuParts.part8;
                        this.SkuParts.part9 = ret.skuParts.part9;
                        this.SkuParts.part10 = ret.skuParts.part10;
                        this.SkuParts.part11 = ret.skuParts.part11;
                        
                    })
                    .catch((err)=> function(){
                        //error
                        console.log(err.response)
                    })
    }
},
beforeDestroy() { 
    window.removeEventListener('resize'); 
},
created: function () {   
   // when the page is loaded look for a product ID query parameter
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    this.productID = params.get("product");

    this.designID =  params.get("design");

    //using the product ID look up the product information from the database
    axios.get(process.env.VUE_APP_API_URL + '/option-groups/product/get-shopify-handle/' + this.productID, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}})
        .then((response)=>{
            // console.log(response);
            if (response.data.isError == false){

                this.product = response.data.ogProduct;
  
                //get the product data
                axios.get("https://shoprsd.com/products/" + this.product.ShopifyProductAlias + ".json") 
                .then((response)=>{
                    console.log('checking shopify');
                    //console.log(response.data.product);
                    this.ShopifyProduct = response.data.product;
                    this.variantID = this.ShopifyProduct.variants[0].id;
                    this.totalPrice = this.ShopifyProduct.variants[0].price;

                    //get all of the options
                    axios.get(process.env.VUE_APP_API_URL + '/options/builder/' + this.product.ID, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}} ) 
                    .then((response)=>{
                        console.log('checking shopify');
                        //console.log(response.data);
                        this.options = response.data;
                        
                        console.log('loaded');
        this.loaded = true;

                        if (!this.designID){
                             //load designer image
                            var svg = this.options.DesignerImage;

                            
                           svg = svg.replaceAll("<svg", "<svg class='designer-svg' ");
                           

                            svg = svg.replaceAll("[fabric]", this.FabricPattern)
                            svg = svg.replaceAll("[leather]", this.LeatherPattern)
                            svg = svg.replaceAll("[zipper]", this.ZipperPattern)
                            this.designerImageSVG = svg;
                        }
                       
                        
                        this.options.forEach(element => {
                            element.collapsed = true;
                        });

                        
                

                    })
                    .catch(()=> function(){
                        //error
                    })

               
                })
                .catch(()=> function(){
                    //error
                })


            }else{
                //do something elese
            }
          
        })
        .catch(()=> function(){
           //
        })
        
}
}
</script>