
<template>
  <div>
    <div class="product-image" v-html="image"></div>
  </div>
</template>

<style lang="css" scoped>


</style>

<script>
import axios from "axios";

export default {
  name: 'item-iamge',
  data: function(){
    return{
      design:{},
      image:'',
    }
  },
  props: { },
  methods:{
    loadDesign(selGuid){
          axios.get(process.env.VUE_APP_API_URL + '/designs/' + selGuid, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}})
        .then((response)=>{
            //console.log(response.data);
             this.design = response.data;

            var json = JSON.parse(this.design.DesignJson);
            this.image = json.designImage;

        })
        .catch((x)=> function(){
            //error
            console.log(x.response)
        })
    },
  },
   created: function () {
      //this.loadDesign(this.guid);
      console.log('created');
  },
  updated() {
   console.log('updated');
  },
  mounted(){
      console.log('mounted');
      this.guid = this.$el.dataset.guid;
     // console.log(this.guid);
      this.loadDesign(this.guid);
  }
}
</script>
