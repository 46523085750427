
<template>
  <div id="customize-wrapper">
      <button type="button" v-on:click="goToDesign" v-show="showButton==true" class="btn btn--full btn-customize">Customize</button>
  </div>
</template>

<style lang="css" scoped>
  #customize-wrapper{
    margin:10px 0px;
  }
</style>

<script>
import axios from "axios";

export default {
  name: 'customize',
  data: function(){
    return{
      productID: 0,
      showButton: false,
      dataCustomizeUrl: ''
    }
  },
  props: {
    msg: String
  },
  methods:{
    goToDesign: function (){
      window.location.href = '/pages/product-designer?product=' + this.productID;
    }
  },
  created: function () {
    //console.log(this);
      var currentUrl = window.location.pathname;

      axios.get(currentUrl + ".json")
      .then(response => {
        this.productID = response.data.product.id
   
        //look up the product ID to see if there is an option group set up for the product
        axios.get(process.env.VUE_APP_API_URL + '/option-groups/product/has-options/' + this.productID, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}})
        .then((response)=>{
         if (response.data == true){
            this.showButton= true;
         }else{
           this.showButton= false;
         }
        })
        .catch(()=> function(){
           this.showButton = false;
        })
        
      })  
      .catch(()=> function () {
        this.showButton = false;
    });
  }
}
</script>