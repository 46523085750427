
<template>
  <div>
        <div class="product-details" >
            <!-- <div class="product-image" v-html="image"></div> -->

            <div class="product-options-list">
                <div class="option-wrapper" v-for="opt in sorted_options(options)" :key="opt.index">
                    <div class="option-title">{{opt.title}}</div>
                    <div class="option-item">
                        <img v-if="opt.image" :src="'https://shoprsd.s3.amazonaws.com/'  + opt.image" class="option-image"/>
                        <span class="option-value">{{opt.text}} <span class="upcharge" v-if="opt.price">{{formatPrice(opt.price)}}</span></span>
                    </div>
                    
                </div> 
            </div>
            <div class="item-sku" style="display:none;">
                SKU: <span>{{sku}}</span>
            </div>
            <div class="option-actions">
                <div class="option-edit">
                    <a class="btn btn--secondary btn--small small--hide" :href="'/pages/product-designer?product=' + design.ShopifyProductID + '&design=' + design.Guid">Modify</a>
                </div>
            
            </div>
        </div>
     
  </div>
</template>

<style lang="css" scoped>
    .option-item{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom:20px;
    }

    .option-title{
        font-weight:700;
        font-size:16px;
    }

    .option-image{
        max-width:30px;
        border-radius:30px;
        margin-right:10px;
    }

    .option-value{
        color: silver;
        font-style: italic;
    }
    .option-value .upcharge{
        font-weight:bold;
    }

    .item-sku{
        font-size:12px;
        color:#333;
        margin-bottom:20px;
    }


</style>

<script>
import axios from "axios";

export default {
  name: 'item-properties',
  data: function(){
    return{
      productID: 0,
      design:{},
      image:'',
      options:'',
      sku:'',
      name: '',
      price:'',
      guid:''
    }
  },
  props: {
    msg: String
  },
  methods:{
    sorted_options(options) {
        return [].slice.call(options).sort((a, b) => { return a.displayOrder - b.displayOrder;});
    },
    formatPrice(sPrice){
        if (sPrice != null){
            return "+$" + Number(sPrice).toFixed(0);
        }
    },
    loadDesign(selGuid){
          axios.get(process.env.VUE_APP_API_URL + '/designs/' + selGuid, {headers: {'X-Api-Auth-Token' : process.env.VUE_APP_API_Key}})
        .then((response)=>{
            console.log(response.data);
             this.design = response.data;

            var json = JSON.parse(this.design.DesignJson);
            this.image = json.designImage;
            this.options = json.selectedOptions;
            this.name = json.name;
            this.price = json.price;
            this.sku = json.sku;

            console.log(this.options);

        })
        .catch((x)=> function(){
            //error
             console.log(x.response);
        })
    },
  },
  created: function () {
      //this.loadDesign(this.guid);
      console.log('created');
  },
  updated() {
   console.log('updated');
  },
  mounted(){
      console.log('mounted');
      this.guid = this.$el.dataset.guid;
     // console.log(this.guid);
      this.loadDesign(this.guid);
  }
}
</script>